<template>
  <div class="content">
    <!--  -->
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="待查重" name="2"></el-tab-pane>
      <el-tab-pane label="已查重" name="5"></el-tab-pane>
    </el-tabs>
    <orderList :state="activeName"></orderList>
  </div>
</template>

<script>
import orderList from "./checkListComponents.vue";

export default {
  components: {
    orderList,
  },
  data() {
    return {
      activeName: "2",
    };
  },
  mounted() {},

  filters: {},
  methods: {
    handleClick(tab, event) {
      // console.log(tab, this.activeName);
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .search-top {
    height: 40px;
    float: right;
  }

  .search-bar {
    display: flex;
    justify-content: space-between;
  }
  .option-list-box {
    display: flex;
    span {
      cursor: pointer;
    }
  }
  .page-box {
    text-align: right;
  }
  .ml10r {
    margin-left: 10px;
  }
}
</style>