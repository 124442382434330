<template>
  <div class="content">
    <div style="clear: both"></div>
    <div class="search-bar">
      <el-form
        :inline="true"
        :model="listQuery"
        ref="departmentInfo"
        size="small"
      >
        <el-form-item style="margin-left: 20px">
          <el-input
            v-model.trim="listQuery.orderId"
            placeholder="订单号"
            style="width: 200px"
            maxlength="64"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item style="margin-left: 20px">
          <el-date-picker
            v-model="searchCondition_date"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :picker-options="pickerOptions0"
            @change="searchConditionDateChage"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" @click="searchBtn"
            >搜索</el-button
          >
          <el-button size="mini" @click="resetBtn">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      stripe
      :height="tableHeight"
      border
    >
      <el-table-column type="index" label="序号" width="65"> </el-table-column>
      <el-table-column
        prop="orderId"
        label="订单号"
        min-width="300"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="fileName"
        label="文件名称"
        min-width="200"
        show-overflow-tooltip
      >
      </el-table-column>

      <el-table-column label="支付金额（元）" min-width="120">
        <template slot-scope="scope">
          <span>{{ moneyIsNull(scope.row.exchangePrice) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" min-width="170">
      </el-table-column>
      <el-table-column
        label="重复率"
        prop="duplicateRate"
        min-width="80"
        v-if="state == 1"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.duplicateRate + "%" }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="查重报告" min-width="200" v-if="state == 1">
      </el-table-column> -->

      <el-table-column label="操作" v-slot="scope" min-width="100">
        <div class="option-list-box">
          <el-link
            v-if="scope.row.duplicateState == 0"
            :underline="false"
            type="primary"
            @click="goToDelive(scope.row)"
            >录入结果</el-link
          >
          <el-link
            class="ml10r"
            v-if="scope.row.duplicateState == 0"
            :underline="false"
            type="primary"
            @click="downloadFile(scope.row.fileName, scope.row.fileUrl)"
            >下载文件</el-link
          >
          <el-link
            v-if="scope.row.duplicateState == 1"
            :underline="false"
            type="primary"
            @click="
              downloadFile(scope.row.reportFileName, scope.row.reportFileUrl)
            "
            >下载查重报告</el-link
          >
        </div>
      </el-table-column>
    </el-table>
    <div class="page-box mt20r">
      <el-pagination
        background
        @current-change="getTableList"
        :current-page.sync="listQuery.pageNum"
        :page-size="listQuery.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 编辑用户信息弹窗  -->
    <el-dialog
      title="查重结果"
      :visible.sync="editUserDialogVisible"
      width="500px"
    >
      <el-form
        :inline="true"
        :model="updateExpressInfo"
        ref="updateExpressInfo"
        size="small"
        :rules="updateExpressInfoRules"
        label-width="100px"
      >
        <el-form-item label="重复率" prop="duplicateRate">
          <el-input
            v-model.trim="updateExpressInfo.duplicateRate"
            placeholder="重复率"
            style="width: 250px"
            clearable
          >
            <span slot="append">%</span>
          </el-input>
        </el-form-item>
        <el-form-item label="查重报告" prop="reportFileUrl">
          <el-upload
            class="upload-demo"
            action=""
            :before-upload="beforeUpload"
            :limit="1"
            ref="batchImportUploadBtn"
            :http-request="fileuploadHandle"
            accept=".doc, .docx, .pdf"
          >
            <el-button slot="trigger" size="small" type="primary"
              >选取文件</el-button
            >
            <div slot="tip" class="el-upload__tip">
              只能上传doc、docx、pdf文件
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editUserDialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="confirmEditHandle"
          :disabled="updateingExpressInfo"
          :loading="updateingExpressInfo"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getOrderInfoBypage, uploadReport } from "../../../api/order";
import { fileupload, fileDownload } from "../../../api/uploadfile";
import { moneyIsNull } from "../../../util/commonFun.js";
export default {
  props: {
    state: {
      type: String,
      default: "",
    },
  },
  data() {
    const validateRepetitionRate = (rule, value, callback) => {
      const reg = /^\d+(\.\d{0,2})?$/;
      if (!value) {
        return callback(new Error("请输入重复率"));
      }
      if (!reg.test(value)) {
        return callback(new Error("重复率应大于0，且最多保留两位小数"));
      } else {
        if (+value > 100) {
          return callback(new Error("重复率应小于100"));
        } else {
          callback();
        }
      }
    };
    return {
      moneyIsNull,
      editUserDialogVisible: false,
      listQuery: {
        beginTime: "", //开始时间 yyyy-MM-DD
        endTime: "", //string结束时间 yyyy-MM-DD
        pageNum: 1,
        pageSize: 10,
        state: null, //订单状态  1未付款 2已付款(检测中) 5已完成（已查重） 不传就是查全部
        orderId: "", //订单号
      },
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      searchCondition_date: [], //搜索条件时间
      total: 0,
      tableData: [],
      updateingExpressInfo: false, //是否正在提交
      updateExpressInfo: {
        //录入查重结果
        duplicateRate: "", //重复率
        reportFileName: "",
        reportFileUrl: "", //查重报告
      },
      updateExpressInfoRules: {
        duplicateRate: [
          {
            required: true,
            message: "重复率必须填写",
            trigger: "blur",
          },
          {
            validator: validateRepetitionRate,
            trigger: "blur",
          },
        ],
        reportFileUrl: [
          {
            required: true,
            message: "查重报告必须上传",
          },
        ],
      },
    };
  },

  mounted() {
    this.getTableList();
    //  this.getDepartmentData();
  },
  watch: {
    state(val) {
      console.log(val, "3333333");
      this.getTableList();
    },
  },
  methods: {
    searchConditionDateChage(val) {
      if (val) {
        this.listQuery.beginTime = val[0];
        this.listQuery.endTime = val[1];
      } else {
        this.listQuery.beginTime = "";
        this.listQuery.endTime = "";
      }
    },
    // 上传文件
    fileuploadHandle(params) {
      console.log(params);
      let formData = new FormData();
      formData.append("file", params.file);
      fileupload(formData)
        .then((res) => {
          if (res.code == 1) {
            console.log("成功");
            //清空已上传的文件列表（该方法不支持在 before-upload 中调用）
            this.$refs["batchImportUploadBtn"].clearFiles();
            let data = res.data;
            this.updateExpressInfo.reportFileName = data.originalFilename || "";
            this.updateExpressInfo.reportFileUrl = data.nginxFileReadPath || "";
          } else if (res.code == -1) {
            this.updateingExpressInfo = false;
            //未登陆
            this.$router.push("/");
          } else {
            this.updateingExpressInfo = false;
            this.$message.error(res.msg);
          }
        })
        .catch((e) => {});
    },
    //确定提交
    confirmEditHandle() {
      this.$refs["updateExpressInfo"].validate((volid) => {
        if (volid) {
          this.updateingExpressInfo = true; //禁用提交按钮
          uploadReport(this.updateExpressInfo)
            .then((res) => {
              if (res.code == 1) {
                this.getTableList();
                this.editUserDialogVisible = false;
              } else {
                this.$message.error(res.msg);
              }
              this.updateingExpressInfo = false; //释放禁用提交按钮
            })
            .catch((e) => {
              this.$message.error(e);
              this.updateingExpressInfo = false; //释放禁用提交按钮
            });
        }
      });
    },

    //列表查询
    getTableList() {
      // this.listQuery.pages = val;
      this.getTableData();
    },
    getTableData() {
      this.listQuery.state = this.state;
      getOrderInfoBypage(this.listQuery).then((res) => {
        if (res.code == 1) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //搜索按钮
    searchBtn() {
      this.listQuery.pageNum = 1;
      this.getTableList();
    },
    // 重置
    resetBtn() {
      this.searchCondition_date = "";
      this.listQuery.orderId = "";
      this.listQuery.beginTime = "";
      this.listQuery.endTime = "";
      this.getTableData();
    },

    //录入结果
    goToDelive(row) {
      if (this.$refs["updateExpressInfo"] !== undefined) {
        this.$refs["updateExpressInfo"].resetFields();
      }
      this.editUserDialogVisible = true;
      this.updateExpressInfo.recordId = row.recordId;
    },
    beforeUpload(file) {
      // 上传之前禁用表单确定按钮，等上传完成才可点击
      this.updateingExpressInfo = false;
      const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);

      const whiteList = ["pdf", "doc", "docx"];

      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message.error("上传文件只能是 pdf、doc、docx格式");
        return false;
      }
    },

    downloadFile(fileName, filePath) {
      fileDownload({
        filePath: filePath,
        fileName: fileName,
      })
        .then((res) => {
          const blob = new Blob([res.data]);
          var downloadElement = document.createElement("a");
          var href = window.URL.createObjectURL(blob); //创建下载的链接
          downloadElement.href = href;
          // 文件名一般是在res.headers里：content-disposition;fileName=xxxxxxxxxx.csv，这个让后端统一规定文件名怎么放前端就怎么取就行
          let str =
            typeof res.headers["content-disposition"] === "undefined"
              ? res.headers["Content-Disposition"].split(";")[1]
              : res.headers["content-disposition"].split(";")[1];

          let filename =
            typeof str.split("fileName=")[1] === "undefined"
              ? str.split("filename=")[1]
              : str.split("fileName=")[1];
          downloadElement.download = decodeURIComponent(filename); //下载后文件名
          document.body.appendChild(downloadElement);
          downloadElement.click(); //点击下载
          document.body.removeChild(downloadElement); //下载完成移除元素
          window.URL.revokeObjectURL(href); //释放掉blob对象
        })
        .catch((e) => {
          this.batchImportUploadIng = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .search-top {
    height: 40px;
    float: right;
  }

  .search-bar {
    display: flex;
    justify-content: space-between;
  }
  .option-list-box {
    display: flex;
    span {
      cursor: pointer;
    }
  }
  .page-box {
    text-align: right;
  }
  .ml10r {
    margin-left: 10px;
  }
}
</style>