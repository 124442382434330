import request from "@/base/util/request.js";

/**
 * POST
 * ​/qixianggongyingpingtai_api​/duplicatecheckbackstage/duplicatecheckRecordInfo​/uploadReport
 * 查重记录订单列表筛选支持状态筛选
 *  @param {*} data 
 * @returns 
 */
export const uploadReport = (data) => {
        return request({
            url: "duplicatecheckbackstage/duplicatecheckRecordInfo/uploadReport",
            method: "post",
            data,
        })

    }
    /**
     * POST
     * /qixianggongyingpingtai_api​/duplicatecheckbackstage/duplicatecheckRecordInfo​/getOrderInfoBypage
     * 查重记录订单列表筛选支持状态筛选
     * @param {*} data 
     * @returns
     */
export const getOrderInfoBypage = (data) => {
    return request({
        url: "duplicatecheckbackstage/duplicatecheckRecordInfo/getOrderInfoBypage",
        method: "post",
        data,
    })

}